define([
    "../transitionsModule",
    "img!../../images/intime.png",
    "img!../../images/warning.png",
    "img!../../images/late.png"
], function (module, intime, warning, late) {
    "use strict";

    return module.controller("MonitoringTransitionOverviewController", [
        "$scope",
        "$q",
        "monitoringService",
        "reload",
        "loadingService",
        "transitionService",
        "remoteExceptionHandler",
        "transitionFailureReasonModal",
        function ($scope, $q, monitoringService, reload, loadingService, transitionService, remoteExceptionHandler, transitionFailureReasonModal) {
            $scope.serviceName = monitoringService.getExpectedTransitionsFilterUrl();
            var dateNow = Date.now();
            var template = [], paginationApi, paginationApiDefer = $q.defer();
            var filterApi, filterApiDefer = $q.defer();
            var checkedTransitions = [];

            $scope.search = function (modifiedTemplate) {
                checkedTransitions.length = 0;
                template = modifiedTemplate;
                paginationApi.seekPage(1);
            };

            $scope.onRegisterFilterApi = function (api) {
                filterApi = api;
                filterApiDefer.resolve();
            };

            $scope.getStatusImage = function (transition) {
                if (isDelayOk(transition)) {
                    return intime;
                }

                if (isDelayWarning(transition)) {
                    return warning;
                }

                if (isDelayLate(transition)) {
                    return late;
                }
            };

            function isDelayOk(transition) {
                return  dateNow <= transition.limitTimestamp && dateNow <= transition.estimatedTimestamp;
            }

            function isDelayWarning(transition) {
                return transition.estimatedTimestamp > dateNow && transition.limitTimestamp < dateNow;
            }

            function isDelayLate(transition) {
                return dateNow > transition.limitTimestamp && dateNow > transition.estimatedTimestamp;
            }

            $scope.tableOptions = {
                records: [],
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return loadingService(monitoringService.filterExpectedTransitions(template, firstResult, maxResults))
                            .then(function (result) {
                                return result;
                            }).catch(remoteExceptionHandler());
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                    paginationApiDefer.resolve();
                }
            };

            $scope.isTransitionChecked = function (transition) {
                return checkedTransitions.indexOf(transition) !== -1;
            };

            $scope.toggleCheck = function (transition) {
                if (!$scope.isTransitionChecked(transition)) {
                    checkedTransitions.push(transition);
                } else {
                    checkedTransitions.splice(checkedTransitions.indexOf(transition), 1);
                }
            };

            $scope.finalizeTransitions = function () {
                return transitionService.accomplishTransitions(checkedTransitions.map(function (transition) {
                    return transition.id;
                })).then(function () {
                    return $scope.search(template);
                });
            };

            $scope.notAccomplishTransitions = function () {
                var selections = checkedTransitions.map(function (transition) {
                    return transition.id;
                });
                return transitionFailureReasonModal(selections)
                    .then(function () {
                        return $scope.search(template);
                    });
            };

            $scope.hasTransitionSelection = function () {
                return checkedTransitions.length;
            };
        }]);
});