define(["./commentModule", "text!./commentDetails.html"], function (commentModule, template) {
    "use strict";

    commentModule.directive("commentDetails", ["commentService", function (commentService) {
        return {
            restrict: "E",
            scope: {
                comment: "<value",
                urlDownloadAttachments: "<?"
            },
            template: template,
            controller: ["$scope", function ($scope) {
                $scope.hasAttachments = function () {
                    return $scope.urlDownloadAttachments && $scope.comment.hasAttachments;
                };

                $scope.downloadAttachments = function () {
                    return commentService.downloadAttachments($scope.urlDownloadAttachments, $scope.comment);
                };
            }]
        };
    }]);
});