define([
    "./transitionsModule",
    "text!../extension/AddNewExtension.html",
    "json!../monitoring.json"
], function (module, extensionModalTemplate,monitoring) {
    "use strict";

    return module.service("transitionService", [
        "monitoringService",
        "messagesModal",
        "loadingService",
        "mbrService",
        "remoteExceptionHandler",
        "remoteMessageModal",
        "TransitionStatus",
        "extensionService",
        "$modal",
        "$http",
        function (monitoringService, messagesModal, loadingService, mbrService, remoteExceptionHandler, remoteMessageModal, TransitionStatus, extensionService, $modal, $http) {
            var self = this;

            self.isFinalized = function (transition) {
                var transitionStatus = transition.status;
                return transitionStatus === TransitionStatus.FINALIZED ||
                    transitionStatus === TransitionStatus.NOT_ACCOMPLISHED ||
                    transitionStatus === TransitionStatus.CANCELLED;
            };

            self.accomplishTransitions = function (ids, timestamp) {
                return changeTransitionsStatus(
                    "monitoring.transition.header.finalize.confirmation",
                    ids,
                    TransitionStatus.FINALIZED,
                    timestamp || Date.now(),
                    []);
            };

            self.notAccomplishTransition = function (id, timestamp, comment, failureReasonId, notDeliveredVolume, notDeliveredWeight) {
                return loadingService(extensionService.getRequiredTransitionExtensions(id))
                    .catch(remoteExceptionHandler())
                    .then(function(requiredExtensions) {
                        if (requiredExtensions.length) {
                            return addNewTransitionExtension(requiredExtensions);
                        }
                        return [];
                    })
                    .then(function (extensions) {
                        extensions.push(...getNotDeliveredVolumeAndWeightExtensions(notDeliveredVolume, notDeliveredWeight));
                        return changeTransitionsStatus(
                            "monitoring.transition.header.notAccomplish.confirmation",
                            [id],
                            TransitionStatus.NOT_ACCOMPLISHED,
                            timestamp || Date.now(),
                            extensions,
                            comment,
                            failureReasonId);
                    })
            };

            self.notAccomplishTransitions = function (ids, comment, failureReasonId, notDeliveredVolume, notDeliveredWeight) {
                return changeTransitionsStatus("monitoring.transition.header.notAccomplish.confirmation",
                    ids,
                    TransitionStatus.NOT_ACCOMPLISHED,
                    Date.now(),
                    getNotDeliveredVolumeAndWeightExtensions(notDeliveredVolume, notDeliveredWeight),
                    comment,
                    failureReasonId);
            };

            function getNotDeliveredVolumeAndWeightExtensions(notDeliveredVolume, notDeliveredWeight){
                let notDeliveredExtensions = [];
                if(notDeliveredVolume != null){
                    let notDeliveredVolumeConfigurations = {
                        dataType:"float",
                        extensionKey: "notDeliveredVolume"
                    };
                    notDeliveredExtensions.push( ...extensionService.newExtensions([notDeliveredVolumeConfigurations]));
                    notDeliveredExtensions[0].floatValue = notDeliveredVolume;
                }
                if(notDeliveredWeight != null) {
                    let notDeliveredWeightConfigurations = {
                        dataType: "float",
                        extensionKey: "notDeliveredWeight"
                    };

                    notDeliveredExtensions.push( ...extensionService.newExtensions([notDeliveredWeightConfigurations]));
                    notDeliveredExtensions[notDeliveredExtensions.length-1].floatValue = notDeliveredWeight;
                }
                return notDeliveredExtensions;
            }

            self.getTimestampStatus = function (transitionid) {
                return getTimestampStatusService(transitionid);
            }


            function addNewTransitionExtension(requiredExtensions) {
                return $modal.open({
                    template: extensionModalTemplate,
                    controller: ["$scope", function ($scope) {
                        $scope.requiredExtensions = requiredExtensions;
                        $scope.extensions = extensionService.newExtensions(requiredExtensions);
                        $scope.save = function () {
                            $scope.$close($scope.extensions);
                        };
                        $scope.back = function () {
                            $scope.$dismiss();
                        };
                    }]
                }).result;
            }

            function getTimestampStatusService(transitionid) {
                return $http.post(getTimestampStatusServicePath(), transitionid).then(function (response) {
                    return response.data;
                });
            }

            function getTimestampStatusServicePath() {
                return monitoring.baseUrl + monitoring.services.timestampStatus;
            }

            function changeTransitionsStatus(warningMessage, transitionIds, newStatus, timestamp, extensions, comment, failureReasonId) {
                return loadingService(monitoringService.findTransitionsById(transitionIds).catch(remoteExceptionHandler()))
                    .then(function (transitions) {
                        return messagesModal.cancellable("dialog.warning", [{
                            keyBundle: warningMessage,
                            parameters: transitions.map(function (transition) {
                                return transition.sourceId;
                            })
                        }])
                            .then(function(){
                                return transitions;
                            });
                    }).then(function (transitions) {

                        transitions = transitions.map(function (transition) {
                            return {
                                "transitionId": transition.id,
                                "latitude": null,
                                "longitude": null,
                                "status": newStatus,
                                "finalizedTimestamp": timestamp,
                                "extensions": extensions,
                                "completionSource": "FrontEnd",
                                "comment": comment,
                                "failureReasonId": failureReasonId
                            };
                        });
                        var data = new FormData();
                        data.append("transitions", new Blob([JSON.stringify(transitions)], {
                            type: "application/json"
                        }));
                        var files = comment? comment.files : null;
                        if (files) {
                            for (var index in files) {
                                data.append("attachments", files[index]);
                            }
                        }
                        return loadingService(mbrService.transitionChangeStatus(data).
                        catch(

                            remoteExceptionHandler()));
                    }).then(function (remoteMessages) {
                        return remoteMessageModal(remoteMessages);
                    });
            }

            return self;
        }]);
});