define([
    "./occurrencesModule",
    "text!./nlgOccurrenceComments.html"
], function (module, template) {
    "use strict";

    return module.directive("nlgOccurrenceComments", [function () {
        return {
            restrict: "E",
            scope: {
                occurrence: "=",
                editable: "&?"
            },
            template: template,
            controller: ["$scope", "occurrenceService", "commentService", "loadingService", "remoteExceptionHandler", function ($scope, occurrenceService, commentService, loadingService, remoteExceptionHandler) {
                $scope.createNewOccurrence = function () {
                    return occurrenceService.createNewOccurrenceForMonitorable($scope.monitorable.id);
                };

                $scope.getUrlDownloadAttachment = function (comment) {
                    return commentService.getAttachmentUrlForOccurrence(comment);
                };

                $scope.isStatusFinal = function (occurrence) {
                    return occurrence.status.statusType === "br.com.neolog.monitoring.monitorable.model.api.occurrence.OccurrenceStatusType.FINAL";
                };

                $scope.submitComment = function (occurrence, comment) {
                    return commentService.addOccurrenceComment(occurrence.id, comment)
                        .then(function () {
                            return loadingService(occurrenceService.findById(occurrence.id))
                                .catch(remoteExceptionHandler());
                        })
                        .then(function (_occurrence) {
                            occurrence.status = _occurrence.status;
                            occurrence.comments = _occurrence.comments;
                        });
                };
            }]
        };
    }])
});