define(["./temperatureModule", "angular", "lodash"], function (module, angular, _, d3) {
    "use strict";
    return module.controller("MonitoringTemperatureController", [
        "$scope",
        "$translate",
        "monitoringService",
        "trackingService",
        "$q",
        "$stateParams",
        "$interval",
        "loadingService",
        "remoteExceptionHandler",
        "monitoringQueryService",
        function ($scope, $translate, monitoringService, trackingService, $q, $stateParams, $interval, loadingService, remoteExceptionHandler, monitoringQueryService) {
            const TEMPERATURE = "br.com.neolog.tracking.model.api.SensorDataType.TEMPERATURE";
            const CONTAINER_DOOR = "br.com.neolog.tracking.model.api.SensorDataType.CONTAINER_DOOR";
            const queryName = "MonitorablesTemperatureSignals";

            $scope.model = {};
            $scope.sensors = [];
            $scope.monitorableId = $stateParams.monitorableId;
            $scope.chartInput = {
                configs: {
                    chartTitle: "Temperatura X Tempo"
                },
                data: {
                    temperatures: [],
                    ranges: []
                },
                loadedData: false,
                chartControl: false,
                setData: function (temperatures, ranges) {
                    this.data.temperatures = temperatures;
                    this.data.ranges = ranges;
                    this.loadedData = true;
                },
                cleanData: function () {
                    this.data = {
                        temperatures: [],
                        ranges: []
                    };
                    this.loadedData = false;
                },
                refreshChartControl: function () {
                    this.chartControl = !this.chartControl;
                },
            };
            $scope.canExport = false;

            $q.resolve(monitoringService.findMonitorableById($scope.monitorableId))
                .then(function (monitorable) {
                    $scope.monitorable = monitorable;
                });

            $scope.getMonitorableDevices = function () {
                return trackingService.findDeviceByMonitorableId($scope.monitorableId)
                    .catch(remoteExceptionHandler)
                    .then(function (devices) {
                        return devices;
                    });
            };

            $scope.formatTag = function (entity) {
                if (!entity) {
                    return "";
                }
                return entity.identifier.toString();
            };

            function loadData(monitorableId, deviceId, temperatureRangeConfigId) {
                let loadPromises = [trackingService.getSignalsByMonitorableAndDevice(monitorableId, deviceId),
                    monitoringService.getTemperatureRangeConfiguration(temperatureRangeConfigId)];
                $q.all(loadPromises).then(function (resolutions) {
                    let temperatures = temperatureGraphPresentation(resolutions[0]);
                    let ranges = rangeGraphPresentation(resolutions[1]);
                    $scope.chartInput.setData(temperatures, ranges);
                });
            }

            function temperatureGraphPresentation(signals) {
                var temperatures = [];
                signals.forEach(function (signal) {
                    var temperatureData = signal.sensorData.find(function (data) {
                        return (data.sensorType === TEMPERATURE && data.configurationIdentifier === $scope.model.sensor.identifier);
                    });
                    var doorOpenData = signal.sensorData.find(function (data) {
                        return data.sensorType === CONTAINER_DOOR;
                    });
                    if (temperatureData) {
                        temperatures.push([temperatureData.temperature,
                            signal.signalTime,
                            doorOpenData ? doorOpenData.containerDoorOpen : null]);
                    }
                });
                return temperatures;
            }

            function rangeGraphPresentation(config) {
                var ranges = [];
                var rawRanges = [config.centerRange,
                    config.lowerCriticalRange,
                    config.lowerWarningRange,
                    config.topCriticalRange,
                    config.topWarningRange];

                rawRanges.forEach(function (rawRange) {
                    var range = [];
                    range.push(rawRange.minTemperature);
                    range.push(rawRange.maxTemperature);
                    range.push(getColor(rawRange.temperatureRangeType));
                    ranges.push(range);
                });
                return ranges;
            }

            function getColor(rangeName) {
                if (rangeName.indexOf('CRITICAL') > -1) {
                    return 'red';
                }
                if (rangeName.indexOf('WARNING') > -1) {
                    return 'yellow';
                }
                return 'green';
            }

            $scope.deviceUpdate = function () {
                if (!$scope.model.device) {
                    $scope.model.sensor = null;
                    $scope.sensors = [];
                    $scope.hasSensors = false;
                    $scope.chartInput.cleanData();
                    $scope.canExport = false;
                } else {
                    monitoringService.findSensorsByMonitorableIdAndDeviceIdAndProviderId($scope.monitorableId, $scope.model.device.deviceId, $scope.model.device.providerId)
                        .catch(remoteExceptionHandler)
                        .then(function (sensors) {
                            $scope.sensors = sensors;
                            $scope.hasSensors = !!$scope.sensors.length;
                        });
                }
            };

            $scope.submit = function () {
                if (!($scope.model.sensor && $scope.model.device)) {
                    $scope.chartInput.cleanData();
                    $interval.cancel($scope.reloadDataInterval);
                    $scope.canExport = false;
                    return;
                }
                $scope.canExport = true;
                loadData($scope.monitorable.id, $scope.model.device.deviceId, $scope.model.sensor.temperatureRangeConfig.id);
                $scope.reloadDataInterval = $interval(function () {
                    loadData($scope.monitorable.id, $scope.model.device.deviceId, $scope.model.sensor.temperatureRangeConfig.id);
                    $scope.chartInput.refreshChartControl();
                }, 900000);
            };


            $scope.temperatureReport = null;
            monitoringQueryService.getQuery(queryName)
                .then(function (report) {
                    $scope.temperatureReport = report;
                });

            $scope.model.fileType = "xlsx";
            $scope.exportTemperatureSpreadsheet = function () {
                var queryData = {
                    "monitorable.trip.code": $scope.monitorable.sourceId,
                    "SensorConfiguration.id": $scope.model.sensor.id
                };
                loadingService(monitoringQueryService.exportSpreadsheet($scope.temperatureReport.id, queryData, $scope.model.fileType))
                    .catch(remoteExceptionHandler());
            };
        }
    ]);
});