define([
    "text!./transitionAccordion.html",
    "./transitionsModule",
    "front-end-web-commons/app/arrays/arrays",
    "angular",
    "img!../images/intime.png",
    "img!../images/warning.png",
    "img!../images/late.png"
], function (template, module, arrays, angular, intimeImage, warningImage, lateImage) {
    "use strict";

    return module.directive("transitionAccordion", [
        "remoteExceptionHandler",
        "occurrenceService",
        "$q",
        "mbrService",
        "loadingService",
        "commentService",
        "transitionFailureReasonModal",
        function (remoteExceptionHandler, occurrenceService, $q, mbrService, loadingService, commentService,transitionFailureReasonModal) {

            return {
                restrict: "E",
                template: template,
                scope: {
                    locality: "=",
                    monitorable: "=",
                    transitions: "=",
                    saveCallback: "&?"
                },
                controller: ["$scope", "messagesModal", "monitoringService", "TransitionStatus", "transitionService", function ($scope, messagesModal, monitoringService, TransitionStatus, transitionService) {
                    $scope.intimeImage = intimeImage;
                    $scope.warningImage = warningImage;
                    $scope.lateImage = lateImage;
                    $scope.realizedTimestamps = {};
                    $scope.status = null;
                    arrays.each($scope.transitions, function (transition) {
                        $scope.realizedTimestamps[transition.id] = transition.estimatedTimestamp;
                    });
                    $scope.$watch("transitions", function (newTransitions) {
                        $scope.realizedTimestamps = {};
                        arrays.each(newTransitions || [], function (transition) {
                            $scope.realizedTimestamps[transition.id] = transition.estimatedTimestamp;
                            transitionService.getTimestampStatus(transition.id).then(function (status) {
                                transition.timestampStatus = status.delayStatus;
                                transition.totalDelay = Math.abs(status.totalDelay);
                            });
                        });
                    });

                    $scope.isDelayOk = function (transition) {
                        return transition.timestampStatus === "br.com.neolog.monitoring.transition.TransitionDelayStatus$DelayStatus.IN_TIME";
                    };
                    $scope.isDelayWarning = function (transition) {
                        return transition.timestampStatus === "br.com.neolog.monitoring.transition.TransitionDelayStatus$DelayStatus.WARNING";
                    };
                    $scope.isDelayProblem = function (transition) {
                        return transition.timestampStatus === "br.com.neolog.monitoring.transition.TransitionDelayStatus$DelayStatus.DELAYED";
                    };
                    $scope.calculateDelay = function (transition) {
                        return transition.totalDelay;
                    };

                    $scope.openComments = function (transition) {
                        transition.$isOpen = !transition.$isOpen;
                        if (transition.$isOpen) {
                            return loadTransitionComments(transition);
                        }
                    };

                    function loadTransitionComments(transition) {
                        return loadingService(commentService.findTransitionComments(transition.id))
                            .catch(remoteExceptionHandler())
                            .then(function (comments) {
                                transition.comments = comments;
                            })
                    }

                    $scope.submitComment = function (transition, comment) {
                        return commentService.addTransitionComment(transition.id, comment)
                            .then(function () {
                                return loadingService(commentService.findTransitionComments(transition.id))
                                    .catch(remoteExceptionHandler());
                            }).then(function (comments) {
                                transition.comments = comments;
                            });
                    };

                    $scope.isAccomplished = function (transition) {
                        return transition.status === TransitionStatus.FINALIZED;
                    };

                    $scope.isNotAccomplished = function (transition) {
                        return transition.status === TransitionStatus.NOT_ACCOMPLISHED;
                    };

                    $scope.isFinalized = function (transition) {
                        return transitionService.isFinalized(transition);
                    };

                    $scope.finalized = function (transition) {
                        return transitionService.accomplishTransitions([transition.id], $scope.realizedTimestamps[transition.id])
                            .then(function () {
                                if (angular.isDefined($scope.saveCallback)) {
                                    return $scope.saveCallback();
                                }
                            });
                    };

                    $scope.notAccomplished = function (transition) {
                        return transitionFailureReasonModal([transition.id], $scope.realizedTimestamps[transition.id], $scope.monitorable)
                            .then(function () {
                                if (angular.isDefined($scope.saveCallback)) {
                                    return $scope.saveCallback();
                                }
                            });
                    };

                    $scope.getUrlDownloadAttachment = function (comment) {
                        return commentService.getAttachmentUrlForTransition(comment);
                    };

                    $scope.getOrNA = function (data) {
                        if (data === "" || data == null || data === 0 || data.length === 0) {
                            return "N/A";
                        }
                        return data;
                    };

                }]
            };
        }]);
});
