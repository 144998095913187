define(["angular",
        "../../userManagementModule"],
    function (angular, userManagementModule) {
        "use strict";

        return userManagementModule.controller("UserManagementUserEditionController", [
            "$scope",
            "user",
            "$state",
            "autocompleteFactory",
            "userManagementService",
            "remoteExceptionHandler",
            "loadingService",
            "messagesModal",
            function ($scope, user, $state, autocompleteFactory, userManagementService, remoteExceptionHandler, loadingService, messagesModal) {
                $scope.user = user;

                $scope.isNewInstance = function () {
                    return user.id === null;
                };

                $scope.availablesPermissionGroup = [];
                userManagementService.findAllChildrenHierarchyTreeById(user.permissionGroup.id)
                    .then(function (value) {
                        $scope.availablesPermissionGroup = value;
                    });

                $scope.profiles = function () {
                    return userManagementService.findAllProfiles();
                };

                $scope.restrictedProfiles = function () {
                    return userManagementService.findRestrictedProfiles();
                };

                $scope.passwordFields = {
                    password: "",
                    confirmation: ""
                };

                $scope.saveAndContinue = function () {
                    $scope.saveUser(true);
                }

                $scope.saveUser = function (saveAndContinue) {
                    if ($scope.passwordFields.password !== "" || $scope.passwordFields.confirmation !== "") {
                        if ($scope.passwordFields.password === $scope.passwordFields.confirmation) {
                            $scope.user.password = $scope.passwordFields.password;
                            return doSaveUser(saveAndContinue);
                        } else {
                            return messagesModal("dialog.warning", [{
                                keyBundle: "entity.validator.passwordConfirmation",
                                parameters: []
                            }]);
                        }
                    } else {
                        return doSaveUser(saveAndContinue);
                    }
                };

                function doSaveUser(saveAndContinue) {
                    if ($scope.user.customizedReadOnly) {
                        return messagesModal.confirmation("dialog.warning", ["user.management.user.create.confirmation.customized"])
                            .then(function () {
                                    return saveAndReload(saveAndContinue);
                                }
                            );
                    } else {
                        return saveAndReload(saveAndContinue);
                    }
                }

                function saveAndReload(saveAndContinue) {
                    return loadingService(userManagementService.saveUser($scope.user)
                        .catch(remoteExceptionHandler())
                        .then(function (id) {
                            return messagesModal("dialog.success", [{
                                keyBundle: "entity.validator.successMessage",
                                parameters: []
                            }]).finally(function () {

                                if(saveAndContinue){
                                    return $state.go($state.current, {reload: Date.now()});
                                }
                                return $state.go("userManagement.userEdit", {
                                    userId: id
                                });
                            });

                        }));
                }

                $scope.back = function () {
                    return $state.go("userManagement.user", {
                        reload: true
                    });
                };
            }]);
    });
