define(["./occurrenceListenerModule"], function (module) {
    "use strict";

    return module.controller("MBROccurrenceListenerController", [
        "$scope",
        "MBROccurrenceListenerParamModalService",
        "mbrService",
        function ($scope, modal, mbrService) {
            $scope.openModal = function (index) {
                modal($scope.occurrenceListeners[index]);
            };

            $scope.occurrenceListeners = [];
            mbrService.getOccurrenceListeners()
                .then(function (response) {
                    $scope.occurrenceListeners = response.data;
                });
        }]);
});