define(["../occurrenceCauseModule",
        "json!../../monitoring.json"],
    function (occurrenceCauseModule, monitoring) {
        "use strict";

        return occurrenceCauseModule.controller("OccurrenceCauseSearchController", [
            "$scope",
            "$q",
            "monitoringService",
            "reload",
            "loadingService",
            "remoteExceptionHandler",
            "occurrenceCauseService",
            function ($scope, $q, monitoringService, reload, loadingService, remoteExceptionHandler, occurrenceCauseService) {
                $scope.serviceName = monitoring.services.occurrenceCausesFilter;
                var template, paginationApi, paginationApiDefer = $q.defer();
                var filterApi, filterApiDefer = $q.defer();
                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.onRegisterFilterApi = function (api) {
                    filterApi = api;
                    filterApiDefer.resolve();
                };

                $q.all([paginationApiDefer.promise, filterApiDefer.promise]).then(function () {
                    filterApi.onChangeService(function (service) {
                        if (service === $scope.serviceName) {
                            if (reload) {
                                filterApi.reload();
                            } else {
                                filterApi.reset();
                            }
                        }
                    });
                });

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(monitoringService.filterOccurrenceCauses("/filter", template, firstResult, maxResults))
                                .then(function (result) {
                                    return result;
                                }).catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                $scope.delete = function (id) {
                    return occurrenceCauseService.deleteOccurrenceCause(id)
                        .finally(function () {
                            filterApi.reload();
                        });
                };
            }]);
    });