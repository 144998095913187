define(["../workHoursControlModule"],
    function (workHoursControlModule) {
        "use strict";

        return workHoursControlModule.controller("WorkHoursControlSettingsController", [
            "$scope",
            "loadingService",
            "workHoursControlService",
            "messagesModal",
            "remoteExceptionHandler",
            function ($scope, loadingService, workHoursControlService, messagesModal,remoteExceptionHandler) {
                $scope.model = {
                    entity: null
                };

                function loadSettings() {
                    return workHoursControlService.findWorkHoursControlSettings()
                        .then(function(settings) {
                            $scope.model.entity = settings;
                        }).catch(remoteExceptionHandler());
                }
                loadingService(loadSettings());

                $scope.save = function() {
                    loadingService(workHoursControlService.updateSettings($scope.model.entity))
                        .then( function() {
                            return messagesModal("dialog.success", ["integration.config.successful.edition"]);
                        })
                        .catch(remoteExceptionHandler())
                        .then(loadSettings);
                };
            }]);
    });