define(["./userManagementModule",
        "json!./userManagement.json"],
    function (userManagementModule, userManagement) {
        "use strict";

        userManagementModule.service("userManagementService", ["$http", function ($http) {
            return {
                filter: filter,
                filterProfile: filterProfile,
                findProfileById: findProfileById,
                findAllProfiles: findAllProfiles,
                findRestrictedProfiles: findRestrictedProfiles,
                findAllUsers: findAllUsers,
                filterUser: filterUser,
                findUserById: findUserById,
                findUserByEmail: findUserByEmail,
                getPermissions: getPermissions,
                getAllPermissions: getAllPermissions,
                copyProfile: copyProfile,
                copyUser: copyUser,
                saveProfile: saveProfile,
                getNewUserInstance: getNewUserInstance,
                getNewProfileInstance: getNewProfileInstance,
                saveUser: saveUser,
                deleteProfile: deleteProfile,
                deleteUser: deleteUser,
                findAllChildrenHierarchyTreeById: findAllChildrenHierarchyTreeById

            };

            function filter(template, firstResult, maxResults){
                return $http.post(servicePath("user") + "filter", template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterProfile(template, firstResult, maxResults){
                return $http.post(servicePath("profile") + "/filter" , template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterUser(template, firstResult, maxResults){
                return $http.post(servicePath("user") + "/filter" , template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function findAllProfiles(){
                return $http.get(servicePath("profile"))
                    .then(getData);
            }

            function findRestrictedProfiles(){
                return $http.get(servicePath("restrictedProfile"))
                    .then(getData);
            }

            function findAllUsers(){
                return $http.get(servicePath("user"))
                    .then(getData);
            }

            function findProfileById(id) {
                return $http.get(servicePath("profile") + "/" + id )
                    .then(getData);
            }

            function findUserById(id) {
                return $http.get(servicePath("user") + "/" + id )
                    .then(getData);
            }

            function findUserByEmail(email) {
                return $http.get(servicePath("user") + "/find/user/" + email )
                    .then(getData);
            }

            function getPermissions(profileId){
                return $http.get(servicePath("permissions") + "/" + profileId).then(getData);
            }

            function getAllPermissions(){
                return $http.get(servicePath("allPermissions"));
            }

            function copyProfile(profileId){
                return $http.post(servicePath("copyProfile"), profileId)
                    .then(getData);
            }

            function copyUser(userId){
                return $http.post(servicePath("copyUser"), userId)
                    .then(getData);
            }

            function saveProfile(profile){
                return $http.post(servicePath("saveProfile"), profile)
                    .then(getData);
            }

            function saveUser(userDTO) {
                return $http.post(servicePath("saveUser"), userDTO)
                    .then(getData);
            }

            function getNewUserInstance() {
                return $http.get(servicePath("newUser"))
                    .then(getData);
            }

            function getNewProfileInstance() {
                return $http.get(servicePath("newProfile"))
                    .then(getData);
            }

            function deleteProfile(profileId){
                return $http.delete(servicePath("deleteProfile") + "/" + profileId);
            }

            function deleteUser(userId){
                return $http.delete(servicePath("deleteUser") + "/" + userId);
            }

            function servicePath(serviceId) {
                return userManagement.baseUrl + userManagement.services[serviceId];
            }

            function getData(response){
                return response.data;
            }

            function findAllChildrenHierarchyTreeById(permissionGroupId) {
                return $http.get(servicePath("permission-group-children") + "/" + permissionGroupId)
                    .then(getData)
            }
        }
        ]);
    });
