define([
    "../trackingModule",
    "front-end-web-commons/app/arrays/arrays",
    "lodash"
], function (trackingModule, arrays, _) {
    "use strict";

    return trackingModule.controller("TruckDeviceSearchController", [
        "$scope",
        "trackingService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        "deviceConfigurationService",
        function ($scope, trackingService, loadingService, remoteExceptionHandler, messagesModal, deviceConfigurationService) {
            var paginationApi;
            var template;

            $scope.serviceName = "/truck-device/";
            $scope.search = function (modifiedTemplate) {
                template = modifiedTemplate;
                paginationApi.seekPage(1);
            };

            $scope.tableOptions = {
                records: [],
                columns: getColumnDefinitions(),
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return loadingService(trackingService.truckDeviceFilter(template, firstResult, maxResults))
                            .catch(remoteExceptionHandler())
                            .then(function (filterResult) {
                                showMobileActions(filterResult.result);
                                return filterResult;
                            });
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                }
            };

            $scope.delete = function(truckDevice){
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(trackingService.deleteTruckDevice(truckDevice.id))
                            .catch(remoteExceptionHandler())
                    })
                    .then(function () {
                        return messagesModal("dialog.success", ["entityFilter.delete.ok"])
                    })
                    .finally(function () {
                        return paginationApi.seekPage(1);
                    });
            };

            $scope.isMobile = function (truckDevice) {
                return truckDevice.providerId === "mobile";
            };

            $scope.showQrCode = function (truckDevice) {
                return deviceConfigurationService.openMobileQRCodeModal(truckDevice);
            };

            $scope.sendSms = function (truckDevice) {
                return deviceConfigurationService.sendConfigurationMessage(truckDevice);
            };

            function getColumnDefinitions() {
                var actionColumn = {
                    "width": "90px",
                    "cell": {
                        "template":
                            "<a name='editButton' tooltip=\"{{'searchView.edit' | translate }}\" class='btn mini btn-default' ui-sref='tracking.truckDeviceEdition({ truckDeviceId: record.id })' style='margin-top: 3px; margin-right: 5px'> <span class='fa fa-pencil-square-o'></span> </a>" +
                            "<button name='deleteButton' tooltip=\"{{'searchView.delete' | translate }}\" class='btn mini btn-default' style='margin-top:3px' ng-click='delete(record)'> <span class='fa fa-trash-o'></span> </button> " +
                            "<button name='showQrCode' ng-if='isMobile(record)' tooltip=\"{{'monitoring.mobile.config.qrcode' | translate }}\" class='btn mini btn-default' style='margin-top:3px' ng-click='showQrCode(record)'> <span class='fa fa-qrcode'></span> </button> " +
                            "<button name='sendSmsCode' ng-if='isMobile(record)' tooltip=\"{{'monitoring.mobile.modal.sendMessage' | translate }}\" class='btn mini btn-default' style='margin-top:3px' ng-click='sendSms(record)'> <span class='fa fa-comment-o'></span> </button> "
                    }
                };

                return [actionColumn].concat(arrays.map(["providerId", "description", "deviceId", "plate"], function (property) {
                    var cellValue = "record." + property;
                    var header = "tracking.truckDevice." + property;
                    var result = {
                        sortBy: property,
                        cellName: property,
                        header: header
                    };
                    result.cell = "{{" + cellValue + "}}";
                    return result;
                }));
            }

            function showMobileActions(truckDevices) {
                var containsMobileDevices = _.some(truckDevices, function (truckDevice) {
                    return $scope.isMobile(truckDevice);
                });
                $scope.tableOptions.columns[0].width = containsMobileDevices ? "160px" : "90px";
            }
        }]);
});