define([
    "./commentModule",
    "json!../monitoring.json",
    "json!../mbr.json"
], function (module, monitoring, mbr) {
    "use strict";

    return module.service("commentService", [
        "monitoringService",
        "trackingMobileService",
        "newOccurrenceModal",
        "loadingService",
        "remoteExceptionHandler",
        "$q",
        "downloadBinary",
        "messagesModal",
        "mbrService",
        "$filter",
        function (monitoringService, trackingMobileService, newOccurrenceModal, loadingService, remoteExceptionHandler, $q, downloadBinary, messagesModal, mbrService, $filter) {
            var self = this;

            self.getAttachmentUrlForTransition = function (comment) {
                return mbr.baseUrl + mbr.services.transition + "comment/" + comment.id + "/downloadAttachments";
            };

            self.findTransitionComments = function (transitionId) {
                return monitoringService.findTransitionComments(transitionId);
            };

            self.addTransitionComment = function (transitionId, comment) {
                return loadingService(mbrService.addTransitionComment(transitionId, comment.content, comment.files))
                    .catch(remoteExceptionHandler());
            };

            self.getAttachmentUrlForOccurrence = function (comment) {
                return mbr.baseUrl + mbr.services.occurrence + "/comment/" + comment.id + "/downloadAttachments";
            };

            self.findOccurrenceComments = function (occurrenceId) {
                return monitoringService.findOccurrenceComments(occurrenceId);
            };

            self.addOccurrenceComment = function (occurrenceId, comment) {
                return loadingService(mbrService.addOccurrenceComment(occurrenceId, comment.content, comment.status, comment.files))
                    .catch(remoteExceptionHandler());
            };

            self.downloadAttachments = function (url, comment) {
                var dateFilter = $filter("date");
                var commentDate = dateFilter(comment.timestamp, "yyyy-MM-dd");

                return loadingService(downloadBinary.get(url, "anexos-" + commentDate + ".zip"))
                    .catch(remoteExceptionHandler());
            };

            return self;
        }]);
});