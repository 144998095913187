define([
    "angular",
    "../remoteMessage/remoteMessage",
    "../extension/extension",
    "../driver/monitoringDriver"
], function (angular) {
    "use strict";

    return angular.module("monitorableModule", [
        "ui.router",
        "remoteMessageModule",
        "extensionModule",
        "monitoringDriverModule",
        "tdnHelp"
    ]);
});